var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "plan-budgets",
    },
    [
      _c(
        "el-tabs",
        {
          staticClass: "budget-tabs",
          attrs: { type: "card", addable: !_vm.read_only },
          on: { "tab-add": _vm.handleAddBudget },
          model: {
            value: _vm.selectedTab,
            callback: function ($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Plan Overview", name: "overview" } },
            _vm._l(_vm.budgets, function (entry) {
              return _c("budget-summary", {
                key: entry.budget.id,
                attrs: {
                  plan_id: _vm.plan.id,
                  budget: entry.budget,
                  category: entry.category,
                  freeze: _vm.read_only,
                },
              })
            }),
            1
          ),
          _vm._l(_vm.budgets, function (entry) {
            return _c(
              "el-tab-pane",
              {
                key: entry.budget.id,
                attrs: { label: entry.category.name, name: entry.budget.id },
              },
              [
                _c("budget-block", {
                  attrs: {
                    user: _vm.user,
                    plan_id: _vm.plan.id,
                    budget: entry.budget,
                    category: entry.category,
                    freeze: _vm.read_only,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }