var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "plan-column" },
        [
          _c(
            "div",
            { staticClass: "plan-header" },
            [
              _c("plan-summary", {
                attrs: {
                  plan_id: _vm.selected_plan_id,
                  plans: _vm.ordered_plans,
                  read_only: _vm.readOnly,
                  can_delete: _vm.can_delete_plans,
                  generating: _vm.generating,
                  creating_new_plan: _vm.creating_new_plan,
                },
                on: {
                  export: function ($event) {
                    _vm.planStatementDialogVisible = true
                  },
                  select: _vm.selectPlan,
                  creating: _vm.toggleCreatingPlan,
                  create: _vm.createPlan,
                  update: _vm.updatePlan,
                  delete: function ($event) {
                    _vm.deletePlanDialogVisible = true
                  },
                },
              }),
              _vm.can_see_comments &&
              _vm.selected_plan &&
              !_vm.creating_new_plan
                ? _c(
                    "div",
                    { staticClass: "comments-section" },
                    [
                      _c("plan-comments-block", {
                        attrs: { plan: _vm.selected_plan },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("total-core-supports-block", {
                attrs: { budget_data: _vm.data },
              }),
              _vm.user.require_approval && _vm.readOnly
                ? _c("div", { staticClass: "approval-notice" }, [
                    _vm._v(" Approval is required for all claims "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.selected_plan && !_vm.creating_new_plan
            ? _c("plan-budgets", {
                attrs: {
                  user: _vm.user,
                  plan_id: _vm.selected_plan.id,
                  budgets: _vm.data,
                  read_only: _vm.readOnly,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "statement-dialog",
          attrs: {
            title: "Export Statement",
            visible: _vm.planStatementDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.planStatementDialogVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v("Select the categories to include in this statement"),
          ]),
          _c(
            "div",
            { staticClass: "select-all" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: {
                    indeterminate:
                      _vm.selected_categories.length > 0 &&
                      _vm.selected_categories.length <
                        _vm.used_categories.length,
                  },
                  on: { change: _vm.toggleSelectCategories },
                  model: {
                    value: _vm.all_categories_selected,
                    callback: function ($$v) {
                      _vm.all_categories_selected = $$v
                    },
                    expression: "all_categories_selected",
                  },
                },
                [_vm._v(" All ")]
              ),
            ],
            1
          ),
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.selected_categories,
                callback: function ($$v) {
                  _vm.selected_categories = $$v
                },
                expression: "selected_categories",
              },
            },
            [
              _c(
                "ul",
                { staticClass: "selected-categories-list" },
                _vm._l(_vm.used_categories, function (category) {
                  return _c(
                    "li",
                    { key: category.id },
                    [
                      _c("el-checkbox", { attrs: { label: category.id } }, [
                        _vm._v(_vm._s(category.name)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.planStatementDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.selected_categories.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.generatePDFStatement()
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-document" }), _vm._v(" PDF ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.selected_categories.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.generateCSVStatement()
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-document" }), _vm._v(" CSV ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Warning",
            visible: _vm.deletePlanDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deletePlanDialogVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(" Delete plan "),
            _c("b", [_vm._v(_vm._s(_vm.planDatesName(_vm.selected_plan)))]),
            _vm._v(" ? "),
          ]),
          _c("p", [
            _vm._v(
              "This plan and all related data will be permanently deleted."
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deletePlanDialogVisible = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.deletePlan()
                    },
                  },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.selected_plan
        ? _c("budget-modal", {
            attrs: {
              plan_id: _vm.selected_plan.id,
              categories: _vm.available_categories,
            },
          })
        : _vm._e(),
      _c("claim-modal", {
        attrs: {
          categories: _vm.used_categories,
          data: _vm.data,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }