<template>
    <div class="progress-bar">
        <div
            v-if="percentage > 0"
            class="progress-bar-inner"
            :style="{
                minWidth: `${display_percentage}%`,
            }"
        >
            {{ display_value }}
        </div>
        <div v-else class="progress-bar-empty">
            {{ display_value }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'progress-bar',
    props: {
        percentage: {
            type: Number,
            required: true,
        },
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        display_value() {
            return this.value ?? `${this.percentage.toFixed(1)}%`;
        },
        display_percentage() {
            if (this.percentage < 0) return 0;
            if (this.percentage > 100) return 100;
            return this.percentage;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-bar {
    height: 20px;
    width: 200px;
    border-radius: 10px;
    background-color: $grey;
    font-size: 12px;
}
.progress-bar-inner {
    height: 20px;
    padding: 0 8px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    background-color: $purple-rain;
    color: white;
    justify-content: flex-end;
    overflow: hidden;
}
.progress-bar-empty {
    height: 20px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    color: $black;
}
</style>
