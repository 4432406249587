<template>
    <div class="category-block">
        <div
            v-if="!budget || !category"
            v-loading="true"
            class="loader"
            element-loading-background="rgba(0, 0, 0, 0)"
        />
        <div v-else class="category-block-content">
            <el-row slot="title" :gutter="20" class="header">
                <el-col :span="freeze ? 14 : 8">
                    <label class="category-title">{{ category.name }}</label>
                </el-col>
                <el-col :span="5" class="right">
                    <label>Budget allocated</label>
                    <span v-if="!editing">
                        {{ budget.budget_allocated | currency }}
                    </span>
                    <money
                        v-show="editing"
                        ref="budgetInput"
                        v-model="new_budget_allocated"
                        v-bind="money"
                        class="el-input__inner money"
                    />
                </el-col>
                <el-col :span="5" class="right">
                    <label>Budget spent</label>
                    <span>{{ budget.budget_spent | currency }}</span>
                </el-col>
                <el-col :span="5" class="right">
                    <label>Available budget</label>
                    <span>{{ budget_available | currency }}</span>
                </el-col>
                <el-col v-if="!freeze" :span="6" class="right controls">
                    <el-button
                        v-if="!editing"
                        style="width: 80px"
                        size="medium"
                        @click="startEditing()"
                    >
                        Edit
                    </el-button>
                    <el-button
                        v-else
                        type="primary"
                        size="medium"
                        :loading="loading"
                        style="width: 80px"
                        @click="updateBudget()"
                    >
                        Save
                    </el-button>
                    <el-button
                        v-if="!editing"
                        type="danger"
                        size="medium"
                        @click="deleteDialogVisible = true"
                    >
                        <i class="el-icon-delete"></i>
                    </el-button>
                    <el-button
                        v-else
                        plain
                        type="danger"
                        size="medium"
                        @click="cancelEditing()"
                    >
                        <i class="el-icon-close"></i>
                    </el-button>
                </el-col>
            </el-row>
        </div>

        <el-dialog
            title="Warning"
            :visible.sync="deleteDialogVisible"
            width="30%"
            center
        >
            <span>
                This category and all related claims will be permanently
                deleted.
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button
                    :loading="loading"
                    @click="deleteDialogVisible = false"
                >
                    Cancel
                </el-button>
                <el-button
                    type="danger"
                    :loading="loading"
                    @click="deleteBudget()"
                >
                    Delete
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import ClaimBlock from '@/views/components/ClaimBlock';
import firebase from 'firebase/app';
import {Money} from 'v-money';
import {mixin as clickaway} from 'vue-clickaway';

export default {
    name: 'budget-summary',
    components: {
        Money,
    },
    mixins: [clickaway],
    props: {
        plan_id: {
            type: String,
            default: null,
        },
        budget: {
            type: Object,
            default: null,
        },
        category: {
            type: Object,
            default: null,
        },
        freeze: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
            },
            editing: false,
            new_budget_allocated: 0,

            loading: false,
            deleteDialogVisible: false,
        };
    },
    computed: {
        claims() {
            if (!this.budget) return [];
            return this.budget.claims;
        },
        sortedClaims() {
            if (this.claims) {
                const sorted = [...this.claims].sort((a, b) => {
                    if (a && b && a.date && b.date) {
                        return b.date.seconds - a.date.seconds; // sort descending
                    }
                    return 0;
                });

                // Remove null claims
                // Happened that a deleted claim was still linked to the budget
                return sorted.filter((c) => c);
            }
            return [];
        },
        budget_available() {
            return this.budget.budget_allocated - this.budget.budget_spent;
        },
    },
    mounted() {
        this.$refs.budgetInput?.$el?.addEventListener(
            'focus',
            this.handleFocusBudgetInput
        );
    },
    methods: {
        startEditing() {
            this.new_budget_allocated = this.budget.budget_allocated;
            this.editing = true;
            setTimeout(() => {
                this.$refs.budgetInput?.$el?.focus();
            });
        },
        cancelEditing() {
            this.editing = false;
        },
        async updateBudget() {
            this.loading = true;

            await this.$fire.doc(`budgets/${this.budget.id}`).update({
                budget_allocated: this.new_budget_allocated,
            });

            this.editing = false;
            this.loading = false;
        },
        async deleteBudget() {
            this.loading = true;

            const budget_id = this.budget.id;

            // Delete all claims related to this budget
            if (this.claims) {
                for (let c = 0; c < this.claims.length; c++) {
                    if (this.claims[c]) {
                        await this.$fire
                            .doc(`claims/${this.claims[c].id}`)
                            .delete();
                    }
                }
            }

            // Remove budget ref from plan
            await this.$fire.doc(`plans/${this.plan_id}`).update({
                data: firebase.firestore.FieldValue.arrayRemove({
                    budget: this.$fire.doc(`budgets/${budget_id}`),
                    category: this.$fire.doc(`categories/${this.category.id}`),
                }),
            });

            // Delete the budget object
            await this.$fire.doc(`budgets/${budget_id}`).delete();

            this.deleteDialogVisible = false;
            this.loading = false;
        },
        handleFocusBudgetInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 100);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.category-block {
    min-height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($black, 0.2);

    .category-block-content {
        width: 100%;
    }

    label {
        text-align: left;
        vertical-align: middle;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
    }

    .loader {
        height: 50px;
    }

    .header {
        flex: 1;
        display: flex;
        align-items: center;
        min-height: 60px;

        .category-title {
            font-family: 'Rubik';
            font-size: 14px;
            line-height: 18px;
            color: $black;
            font-weight: 700;
            margin: 10px 0;
        }

        .el-input__inner {
            height: 32px;
            font-family: 'Rubik';
            padding: 0;
            margin-top: -2px;
            margin-left: -5px;
            padding-left: 4px;
            padding-right: 11px;
            color: black;
            font-size: 20px;
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            padding-right: 20px !important;
        }

        .controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 10px !important;
        }

        label {
            font-size: 12px;
            line-height: 16px;
            text-align: right;
        }

        span {
            padding: 0 12px 0 0;
            display: block;
            font-family: 'Rubik';
            font-size: 20px;
            height: 30px;
            line-height: 28px;
        }
        @media (max-width: 1260px) {
            .category-title {
                font-size: 12px;
                line-height: 14px;
            }
            label {
                font-size: 10px;
                line-height: 10px;
            }
            span {
                font-size: 14px;
                height: 14px;
                line-height: 14px;
            }
        }
    }

    .money {
        font-family: 'Rubik';
        text-align: right;
    }
}

::v-deep .el-collapse {
    .el-collapse-item__header,
    .el-collapse-item__wrap {
        background: transparent;
    }
    .el-collapse-item__content {
        border-radius: 0 0 8px 8px;
        background-color: #e5e8eb;
        padding-bottom: 0;
        margin-bottom: 25px;
    }
}
.edit-bar {
    position: absolute;
    top: 10px;
    left: 10px;
}
</style>
