<template>
    <div v-if="total_core_supports !== false" class="total-core-supports-block">
        <span class="label">Total Core supports</span>
        <span class="value">{{ total_core_supports | currency }}</span>
    </div>
</template>

<script>
import firebase from 'firebase/app';

import CommentRow from '@/views/components/CommentRow';

export default {
    name: 'total-core-supports-block',
    props: {
        budget_data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            budgets: null,
        };
    },
    computed: {
        is_pace() {
            // Consumables (3) is not exclusive to PACE users
            return this.budget_data.some(
                (b) =>
                    b.category === 'categories/32' ||
                    b.category === 'categories/34'
            );
        },
        pace_budget_ids() {
            const ids = this.budget_data.reduce((pace, b) => {
                if (
                    b.category === 'categories/3' ||
                    b.category === 'categories/32' ||
                    b.category === 'categories/34'
                ) {
                    pace.push(b.budget.substring(8));
                }
                return pace;
            }, []);
            return ids;
        },
        total_core_supports() {
            if (this.budgets?.length) {
                return this.budgets.reduce(
                    (sum, b) => (sum += b.budget_allocated - b.budget_spent),
                    0
                );
            }
            return false;
        },
    },
    watch: {
        budget_data: {
            handler(value) {
                if (value) {
                    this.bindBudgets();
                }
            },
            immediate: true,
        },
    },
    methods: {
        async bindBudgets() {
            if (this.is_pace) {
                await this.$bind(
                    'budgets',
                    this.$fire
                        .collection('budgets')
                        .where(
                            firebase.firestore.FieldPath.documentId(),
                            'in',
                            this.pace_budget_ids
                        ),
                    {maxRefDepth: 0}
                );
            } else {
                this.budgets = null;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.total-core-supports-block {
    background: white;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .label {
        font-size: 14px;
        font-weight: 500;
        color: #606266;
    }
    .value {
        font-family: 'Rubik';
        font-size: 20px;
    }
}
</style>
