var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-block" },
    [
      !_vm.budget || !_vm.category
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true",
              },
            ],
            staticClass: "loader",
            attrs: { "element-loading-background": "rgba(0, 0, 0, 0)" },
          })
        : _c(
            "div",
            { staticClass: "category-block-content" },
            [
              _c(
                "el-row",
                {
                  staticClass: "header",
                  attrs: { slot: "title", gutter: 20 },
                  slot: "title",
                },
                [
                  _c("el-col", { attrs: { span: _vm.freeze ? 14 : 8 } }, [
                    _c("label", { staticClass: "category-title" }, [
                      _vm._v(_vm._s(_vm.category.name)),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { staticClass: "right", attrs: { span: 5 } },
                    [
                      _c("label", [_vm._v("Budget allocated")]),
                      !_vm.editing
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.budget.budget_allocated
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "money",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editing,
                                expression: "editing",
                              },
                            ],
                            ref: "budgetInput",
                            staticClass: "el-input__inner money",
                            model: {
                              value: _vm.new_budget_allocated,
                              callback: function ($$v) {
                                _vm.new_budget_allocated = $$v
                              },
                              expression: "new_budget_allocated",
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "right", attrs: { span: 5 } }, [
                    _c("label", [_vm._v("Budget spent")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.budget.budget_spent))
                      ),
                    ]),
                  ]),
                  _c("el-col", { staticClass: "right", attrs: { span: 5 } }, [
                    _c("label", [_vm._v("Available budget")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.budget_available))),
                    ]),
                  ]),
                  !_vm.freeze
                    ? _c(
                        "el-col",
                        { staticClass: "right controls", attrs: { span: 6 } },
                        [
                          !_vm.editing
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { size: "medium" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.startEditing()
                                    },
                                  },
                                },
                                [_vm._v(" Edit ")]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "primary",
                                    size: "medium",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateBudget()
                                    },
                                  },
                                },
                                [_vm._v(" Save ")]
                              ),
                          !_vm.editing
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "medium" },
                                  on: {
                                    click: function ($event) {
                                      _vm.deleteDialogVisible = true
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: {
                                    plain: "",
                                    type: "danger",
                                    size: "medium",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEditing()
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Warning",
            visible: _vm.deleteDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              " This category and all related claims will be permanently deleted. "
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.deleteDialogVisible = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.deleteBudget()
                    },
                  },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }