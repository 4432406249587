var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "plan-comments-block" }, [
    _c(
      "div",
      {
        staticClass: "plan-comments-header",
        on: {
          click: function ($event) {
            _vm.showComments = !_vm.showComments
          },
        },
      },
      [
        _c("span", [_vm._v(" Comments: " + _vm._s(_vm.commentCount) + " ")]),
        _vm.showComments
          ? _c("span", [
              _vm._v(" hide "),
              _c("i", { staticClass: "el-icon-caret-bottom" }),
            ])
          : _c("span", [
              _vm._v("show "),
              _c("i", { staticClass: "el-icon-caret-right" }),
            ]),
      ]
    ),
    _vm.showComments
      ? _c("div", { staticClass: "plan-comments-content" }, [
          _c(
            "ul",
            _vm._l(_vm.comments, function (comment) {
              return _c("comment-row", {
                key: comment.id,
                attrs: { comment: comment },
                on: { delete: _vm.deleteComment },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "new" },
            [
              _c("el-input", {
                staticClass: "comment-input",
                attrs: {
                  name: "text",
                  placeholder: "Add comment",
                  size: "mini",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addComment.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-d-arrow-right",
                },
                on: { click: _vm.addComment },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }