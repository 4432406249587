var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "category-block" }, [
    !_vm.budget || !_vm.category
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: true,
              expression: "true",
            },
          ],
          staticClass: "loader",
          attrs: { "element-loading-background": "rgba(0, 0, 0, 0)" },
        })
      : _c(
          "div",
          [
            _c(
              "el-row",
              {
                staticClass: "header",
                attrs: { slot: "title", gutter: 20 },
                slot: "title",
              },
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("label", { staticClass: "category-title" }, [
                    _vm._v(_vm._s(_vm.category.name)),
                  ]),
                ]),
                _c(
                  "el-col",
                  { staticClass: "right", attrs: { span: 4 } },
                  [
                    _c("label", [_vm._v("Budget allocated")]),
                    !_vm.editing
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(_vm.budget.budget_allocated)
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "money",
                          _vm._b(
                            {
                              staticClass: "el-input__inner money",
                              model: {
                                value: _vm.new_budget_allocated,
                                callback: function ($$v) {
                                  _vm.new_budget_allocated = $$v
                                },
                                expression: "new_budget_allocated",
                              },
                            },
                            "money",
                            _vm.money,
                            false
                          )
                        ),
                  ],
                  1
                ),
                _c("el-col", { staticClass: "right", attrs: { span: 4 } }, [
                  _c("label", [_vm._v("Budget spent")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.budget.budget_spent))),
                  ]),
                ]),
                _c(
                  "el-col",
                  { staticClass: "right claim-col", attrs: { span: 8 } },
                  [
                    _c("div", { staticClass: "header-block" }, [
                      _c("label", [_vm._v("Available budget")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.budget_available))
                        ),
                      ]),
                    ]),
                    !_vm.freeze
                      ? _c("claim-block", {
                          attrs: {
                            user: _vm.user,
                            plan_id: _vm.plan_id,
                            budget: _vm.budget,
                            category: _vm.category,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.sortedClaims, function (claim) {
              return _c("claim-block", {
                key: claim.id,
                attrs: {
                  user: _vm.user,
                  plan_id: _vm.plan_id,
                  budget: _vm.budget,
                  category: _vm.category,
                  claim: claim,
                  freeze: _vm.freeze,
                },
              })
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }