<template>
    <div class="plan-comments-block">
        <div class="plan-comments-header" @click="showComments = !showComments">
            <span> Comments: {{ commentCount }} </span>
            <span v-if="showComments">
                hide
                <i class="el-icon-caret-bottom" />
            </span>
            <span v-else>show <i class="el-icon-caret-right" /></span>
        </div>
        <div v-if="showComments" class="plan-comments-content">
            <ul>
                <comment-row
                    v-for="comment in comments"
                    :key="comment.id"
                    :comment="comment"
                    @delete="deleteComment"
                />
            </ul>
            <div class="new">
                <el-input
                    v-model="text"
                    class="comment-input"
                    name="text"
                    placeholder="Add comment"
                    size="mini"
                    @keyup.enter.native="addComment"
                />
                <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-d-arrow-right"
                    @click="addComment"
                />
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';

import CommentRow from '@/views/components/CommentRow';

export default {
    name: 'plan-comments-block',
    components: {
        CommentRow,
    },
    props: {
        plan: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            text: null,
            comments: [],
            commentsLoaded: false,
            showComments: true,
        };
    },
    computed: {
        currentUser() {
            return firebase.auth().currentUser;
        },
        commentCount() {
            if (!this.commentsLoaded) return '';
            return `(${this.comments.length})`;
        },
    },
    watch: {
        plan(value) {
            if (value) {
                this.bindComments();
            }
        },
    },
    mounted() {
        this.bindComments();
    },
    methods: {
        async bindComments() {
            this.commentsLoaded = false;
            await this.$bind(
                'comments',
                this.$fire
                    .collection('comments')
                    .where(
                        'to_plan',
                        '==',
                        this.$fire.doc(`plans/${this.plan.id}`)
                    )
                    .orderBy('date', 'desc'),
                {maxRefDepth: 1}
            );
            this.commentsLoaded = true;
        },
        async addComment(event) {
            let commentText = this.text;
            this.text = '';
            const comment = await this.$fire.collection('comments').add({
                to_plan: this.$fire.doc(`plans/${this.plan.id}`),
                posted_by: this.$fire.doc(`users/${this.currentUser.uid}`),
                text: commentText,
                date: new Date(),
            });
        },

        async deleteComment(comment) {
            await this.$fire.doc(`comments/${comment.id}`).delete();
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.plan-comments-block {
    .plan-comments-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 10px;
        &:hover {
            background: $grey;
        }
        span {
            text-align: left;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 500;
            color: #606266;
            line-height: 40px;
        }
    }
    .plan-comments-content {
        padding: 10px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 175px;
        overflow: auto;
    }

    .new {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .comment-input {
            margin-right: 5px;
            ::v-deep input {
                font-family: 'Rubik';
            }
        }
    }
}
</style>
