var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bar" }, [
    _vm.percentage > 0
      ? _c(
          "div",
          {
            staticClass: "progress-bar-inner",
            style: {
              minWidth: `${_vm.display_percentage}%`,
            },
          },
          [_vm._v(" " + _vm._s(_vm.display_value) + " ")]
        )
      : _c("div", { staticClass: "progress-bar-empty" }, [
          _vm._v(" " + _vm._s(_vm.display_value) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }