<template>
    <el-dialog :visible.sync="show" :show-close="false">
        <el-form auto-complete="on" label-position="left" label-width="200px">
            <el-form-item label="New support category" required>
                <el-select
                    v-model="new_budget.category"
                    placeholder="Choose category"
                    value-key="id"
                    :filterable="true"
                >
                    <el-option
                        v-for="category in categories"
                        :key="category.id"
                        :label="category.name"
                        :value="category"
                    />
                </el-select>
            </el-form-item>

            <el-form-item label="Allocated budget" required>
                <money
                    v-model="new_budget.budget_allocated"
                    v-bind="money"
                    class="el-input__inner money"
                />
            </el-form-item>

            <el-button
                type="primary"
                style="width: 100%"
                :loading="loading"
                :disabled="!is_valid_form"
                @click="createBudget"
            >
                Create budget
            </el-button>
        </el-form>
    </el-dialog>
</template>

<script>
import {Money} from 'v-money';
import firebase from 'firebase/app';
import _ from 'lodash';

import * as Sentry from '@sentry/browser';

export default {
    name: 'budget-modal',
    components: {
        Money,
    },
    props: {
        plan_id: {
            type: String,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            loading: false,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
            },
            new_budget: {
                category: null,
                budget_allocated: 0,
            },
        };
    },
    computed: {
        is_valid_form() {
            return this.new_budget.category !== null;
        },
    },
    mounted() {
        this.$bus.$on('budget-modal', (data) => {
            this.show = true;
        });
    },
    beforeDestroy() {
        this.$bus.$off('budget-modal');
    },
    methods: {
        async createBudget() {
            this.loading = true;

            const budget = await this.$fire.collection('budgets').add({
                budget_allocated: this.new_budget.budget_allocated,
                budget_spent: 0,
            });

            await this.$fire.doc(`plans/${this.plan_id}`).update({
                data: firebase.firestore.FieldValue.arrayUnion({
                    budget: this.$fire.doc(`budgets/${budget.id}`),
                    category: this.$fire.doc(
                        `categories/${this.new_budget.category.id}`
                    ),
                }),
            });

            this.new_budget = {
                category: null,
                budget_allocated: 0,
            };

            this.loading = false;
            this.show = false;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.el-dialog {
    border-radius: 10px;
    overflow: hidden;

    label {
        display: block;
        margin-bottom: 10px;
    }

    .el-select {
        width: 100%;
    }

    .money {
        font-family: 'Rubik';
    }
}
</style>
