var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "plan-summary",
    },
    [
      _c("div", { staticClass: "plan-selector" }, [
        _c("h3", { staticClass: "plan-header" }, [_vm._v("NDIS Plan")]),
        _vm.selected_plan && !_vm.editing_plan && !_vm.creating_new_plan
          ? _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    staticClass: "plan-select",
                    attrs: {
                      placeholder: "Select",
                      "value-key": "expiry",
                      "popper-class": "plan-select-dropdown",
                    },
                    model: {
                      value: _vm.selected_plan_id,
                      callback: function ($$v) {
                        _vm.selected_plan_id = $$v
                      },
                      expression: "selected_plan_id",
                    },
                  },
                  [
                    _vm._l(_vm.plans, function (tmp_plan) {
                      return _c(
                        "el-option",
                        {
                          key: tmp_plan.id,
                          attrs: {
                            label: _vm.planDatesName(tmp_plan),
                            value: tmp_plan.id,
                            disabled: !tmp_plan.start || !tmp_plan.expiry,
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.planDatesName(tmp_plan))),
                          ]),
                          _vm.active_plan && tmp_plan.id === _vm.active_plan.id
                            ? _c("span", { staticClass: "plan-type" }, [
                                _vm._v(" Active "),
                              ])
                            : _vm.isExpired(tmp_plan)
                            ? _c("span", { staticClass: "plan-type" }, [
                                _vm._v(" Expired "),
                              ])
                            : _vm.isFuture(tmp_plan)
                            ? _c("span", { staticClass: "plan-type" }, [
                                _vm._v(" Future "),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    !_vm.read_only
                      ? _c(
                          "el-option",
                          {
                            staticClass: "create-plan-row",
                            attrs: { disabled: "", value: null },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "create-plan-button",
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  size: "medium",
                                },
                                on: { click: _vm.handleClickCreate },
                              },
                              [_vm._v("Create new plan")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "download",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("export")
                      },
                    },
                  },
                  [
                    _vm.generating === true
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _vm.generating === false
                      ? _c("i", { staticClass: "el-icon-check" })
                      : _c("i", { staticClass: "el-icon-document" }),
                    _vm._v(" Export statement "),
                  ]
                ),
                !_vm.read_only
                  ? [
                      _c(
                        "el-button",
                        {
                          staticClass: "edit-button",
                          on: { click: _vm.handleClickEdit },
                        },
                        [_vm._v(" Edit ")]
                      ),
                      _vm.can_delete
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                type: "danger",
                                disabled: _vm.is_selected_plan_active,
                              },
                              on: { click: _vm.handleClickDelete },
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.editing_plan
          ? _c(
              "div",
              [
                _c("el-button", { on: { click: _vm.cancelEdit } }, [
                  _vm._v(" Cancel "),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.updatePlan()
                      },
                    },
                  },
                  [_vm._v(" Save ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.creating_new_plan || !_vm.plans.length
          ? _c(
              "div",
              [
                _vm.plans.length
                  ? _c("el-button", { on: { click: _vm.cancelCreate } }, [
                      _vm._v(" Cancel "),
                    ])
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.createPlan()
                      },
                    },
                  },
                  [_vm._v(" Create ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "alert" },
        [
          _vm.is_plan_expired && !_vm.editing_plan && !_vm.creating_new_plan
            ? _c("el-alert", {
                attrs: {
                  type: "error",
                  closable: false,
                  title: "This plan is expired.",
                },
              })
            : _vm._e(),
          _vm.creating_new_plan
            ? _c("div", [_vm._v("Select plan duration:")])
            : _vm._e(),
          _vm.editing_plan
            ? _c("div", [_vm._v("Select updated plan duration:")])
            : _vm._e(),
        ],
        1
      ),
      _vm.selected_plan && !_vm.creating_new_plan && !_vm.editing_plan
        ? _c(
            "div",
            { staticClass: "budget-bar" },
            [
              _c("div", { staticClass: "budget-available" }, [
                _c("span", { staticClass: "amount" }, [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.budgetAvailable))),
                ]),
                _c("span", { staticClass: "label" }, [_vm._v("Available")]),
              ]),
              _c("progress-bar", {
                staticClass: "budget-spent",
                attrs: {
                  percentage: _vm.budget_spent_percent,
                  value: _vm._f("currency")(_vm.budgetSpent),
                },
              }),
              _c("div", { staticClass: "budget-total" }, [
                _vm._v(" of "),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.budgetTotal))),
                ]),
              ]),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("el-date-picker", {
                staticClass: "plan-duration-picker",
                attrs: {
                  type: "daterange",
                  "range-separator": "to",
                  "start-placeholder": "Start date",
                  "end-placeholder": "Expiry date",
                  "picker-options": _vm.rangeOptions,
                  format: "MMM dd, yyyy",
                },
                model: {
                  value: _vm.updated_range,
                  callback: function ($$v) {
                    _vm.updated_range = $$v
                  },
                  expression: "updated_range",
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }