<template>
    <div class="category-block">
        <div
            v-if="!budget || !category"
            v-loading="true"
            class="loader"
            element-loading-background="rgba(0, 0, 0, 0)"
        />
        <div v-else>
            <el-row slot="title" :gutter="20" class="header">
                <el-col :span="8">
                    <label class="category-title">{{ category.name }}</label>
                </el-col>
                <el-col :span="4" class="right">
                    <label>Budget allocated</label>
                    <span v-if="!editing">
                        {{ budget.budget_allocated | currency }}
                    </span>
                    <money
                        v-else
                        v-model="new_budget_allocated"
                        v-bind="money"
                        class="el-input__inner money"
                    />
                </el-col>
                <el-col :span="4" class="right">
                    <label>Budget spent</label>
                    <span>{{ budget.budget_spent | currency }}</span>
                </el-col>
                <el-col :span="8" class="right claim-col">
                    <div class="header-block">
                        <label>Available budget</label>
                        <span>{{ budget_available | currency }}</span>
                    </div>
                    <claim-block
                        v-if="!freeze"
                        :user="user"
                        :plan_id="plan_id"
                        :budget="budget"
                        :category="category"
                    />
                </el-col>
            </el-row>

            <claim-block
                v-for="claim in sortedClaims"
                :key="claim.id"
                :user="user"
                :plan_id="plan_id"
                :budget="budget"
                :category="category"
                :claim="claim"
                :freeze="freeze"
            />
        </div>
    </div>
</template>

<script>
import ClaimBlock from '@/views/components/ClaimBlock';
import firebase from 'firebase/app';
import {Money} from 'v-money';
import {mixin as clickaway} from 'vue-clickaway';
import auth from '@/utils/auth';

export default {
    name: 'budget-block',
    components: {
        ClaimBlock,
        Money,
    },
    mixins: [clickaway],
    props: {
        user: {
            type: Object,
            required: true,
        },
        plan_id: {
            type: String,
            default: null,
        },
        budget: {
            type: Object,
            default: null,
        },
        category: {
            type: Object,
            default: null,
        },
        freeze: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
            },
            editing: false,
            new_budget_allocated: 0,

            loading: false,
        };
    },
    computed: {
        isAdmin() {
            return (
                auth.role === auth.ROLE_ADMIN ||
                auth.role === auth.ROLE_SUPERADMIN
            );
        },
        claims() {
            return this.budget?.claims?.filter((claim) => claim) ?? [];
        },
        sortedClaims() {
            if (this.claims) {
                const sorted = [...this.claims].sort((a, b) => {
                    if (a && b && a.date && b.date) {
                        return b.date.seconds - a.date.seconds; // sort descending
                    }
                    return 0;
                });

                // If not an admin, remove rejected claims from list
                if (!this.isAdmin) {
                    return sorted.filter(
                        (c) => c && c.approval_status !== 'rejected'
                    );
                }
                return sorted;
            }
            return [];
        },
        budget_available() {
            return this.budget.budget_allocated - this.budget.budget_spent;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.category-block {
    min-height: 50px;
    border-bottom: 1px solid rgba($black, 0.2);

    label {
        text-align: left;
        vertical-align: middle;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
    }

    .loader {
        height: 50px;
    }

    .header-block {
        display: inline-block;
        text-align: right;
        margin-right: 8px;
    }

    .header {
        flex: 1;
        display: flex;
        align-items: center;
        min-height: 60px;

        .category-title {
            font-family: 'Rubik';
            font-size: 14px;
            line-height: 18px;
            color: $black;
            font-weight: 700;
            margin: 10px 0;
            text-align: left;
        }

        .el-input__inner {
            height: 32px;
            font-family: 'Rubik';
            padding: 0;
            margin-top: -2px;
            margin-left: -5px;
            padding-left: 4px;
            padding-right: 11px;
            color: black;
            font-size: 20px;
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            padding-right: 20px !important;
        }

        .claim-col {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        label {
            font-size: 12px;
            line-height: 16px;
            text-align: right;
        }

        span {
            padding: 0 12px 0 0;
            display: block;
            font-family: 'Rubik';
            font-size: 20px;
            height: 30px;
            line-height: 28px;
        }
        @media (max-width: 1260px) {
            .category-title {
                font-size: 12px;
                line-height: 14px;
            }
            label {
                font-size: 10px;
                line-height: 10px;
            }
            span {
                font-size: 14px;
                height: 14px;
                line-height: 14px;
            }
        }
    }

    .money {
        font-family: 'Rubik';
        text-align: right;
    }
}

::v-deep .el-collapse {
    .el-collapse-item__header,
    .el-collapse-item__wrap {
        background: transparent;
    }
    .el-collapse-item__content {
        border-radius: 0 0 8px 8px;
        background-color: #e5e8eb;
        padding-bottom: 0;
        margin-bottom: 25px;
    }
}
.edit-bar {
    position: absolute;
    top: 10px;
    left: 10px;
}
</style>
