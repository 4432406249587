var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.show, "show-close": false },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            "auto-complete": "on",
            "label-position": "left",
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "New support category", required: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Choose category",
                    "value-key": "id",
                    filterable: true,
                  },
                  model: {
                    value: _vm.new_budget.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.new_budget, "category", $$v)
                    },
                    expression: "new_budget.category",
                  },
                },
                _vm._l(_vm.categories, function (category) {
                  return _c("el-option", {
                    key: category.id,
                    attrs: { label: category.name, value: category },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Allocated budget", required: "" } },
            [
              _c(
                "money",
                _vm._b(
                  {
                    staticClass: "el-input__inner money",
                    model: {
                      value: _vm.new_budget.budget_allocated,
                      callback: function ($$v) {
                        _vm.$set(_vm.new_budget, "budget_allocated", $$v)
                      },
                      expression: "new_budget.budget_allocated",
                    },
                  },
                  "money",
                  _vm.money,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: {
                type: "primary",
                loading: _vm.loading,
                disabled: !_vm.is_valid_form,
              },
              on: { click: _vm.createBudget },
            },
            [_vm._v(" Create budget ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }