var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "approval-icon" }, [
    _vm.isPending
      ? _c("i", { staticClass: "pending el-icon-warning" })
      : _vm.isRejected
      ? _c("i", { staticClass: "rejected el-icon-circle-close" })
      : _vm.isApproved
      ? _c("i", { staticClass: "approved el-icon-circle-check" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }