<template>
    <div class="claim-block" :class="{is_row: !!claim, rejected: isRejected}">
        <div v-if="claim" class="claim">
            <el-row :gutter="20" class="header">
                <el-col :span="1" class="center">
                    <el-popover
                        v-if="claim.attachments?.length"
                        placement="top"
                        trigger="hover"
                    >
                        <span class="attachment-label">Attachments</span>
                        <div>
                            <attachment
                                v-for="attachment of claim.attachments"
                                :key="attachment.path"
                                :name="attachment.name"
                                :path="attachment.path"
                                readonly
                            />
                        </div>
                        <i
                            slot="reference"
                            class="attachment"
                            :class="
                                claim.attachments.length > 1
                                    ? 'el-icon-document-copy'
                                    : 'el-icon-document'
                            "
                        />
                    </el-popover>
                    <i v-else class="no-attachment el-icon-document-delete" />
                </el-col>
                <el-col v-if="user.require_approval" :span="1" class="center">
                    <approval-icon :claim="claim" />
                </el-col>
                <el-col :span="detail_width">
                    <div class="info">
                        <span v-if="claim.date">
                            {{
                                claim.date.seconds
                                    | timestamp2Date
                                    | dateformat('MMM D, YYYY')
                            }}
                        </span>
                        <div class="title">
                            <label v-if="claim.provider">
                                {{ claim.provider | capitalize }}
                            </label>
                            <span v-if="claim.invoice_number" class="number">
                                [ {{ claim.invoice_number }} ]
                            </span>
                        </div>
                    </div>
                </el-col>
                <el-col
                    v-if="canApprove && user.require_approval && isPending"
                    :span="4"
                >
                    <approval-action :claim="claim" />
                </el-col>
                <el-col :span="7" class="right">
                    <span class="budget">{{ claim.total | currency }}</span>
                    <el-button v-if="!freeze" @click="showModal()">
                        View
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <div v-else class="new">
            <el-button type="primary" @click="showModal()">
                New claim
            </el-button>
        </div>
    </div>
</template>

<script>
import Attachment from '@/views/components/Attachment';
import moment from 'moment';
import auth from '@/utils/auth';
import ApprovalIcon from '@/views/components/ApprovalIcon';
import ApprovalAction from '@/views/components/ApprovalAction';

export default {
    name: 'claim-block',
    components: {ApprovalAction, ApprovalIcon, Attachment},
    props: {
        user: {
            type: Object,
            required: true,
        },
        plan_id: {
            type: String,
            default: null,
        },
        budget: {
            type: Object,
            default: null,
        },
        category: {
            type: Object,
            default: null,
        },
        claim: {
            type: [String, Object],
            default: null,
        },
        freeze: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detail_width() {
            let width = 16;
            if (this.user.require_approval) {
                width -= 1;
                if (this.isPending && this.canApprove) width -= 4;
            }
            return width;
        },
        isPending() {
            return !!this.claim?.id && this.claim.approval_status === 'pending';
        },
        isRejected() {
            return (
                !!this.claim?.id && this.claim.approval_status === 'rejected'
            );
        },
        canApprove() {
            return (
                auth.user.uid === this.user.id ||
                auth.role === auth.ROLE_ADMIN ||
                auth.role === auth.ROLE_SUPERADMIN
            );
        },
    },
    methods: {
        showModal() {
            this.$bus.$emit('claim-modal', {
                user: this.user,
                budget: this.budget,
                category: this.category,
                claim: this.claim,
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.claim-block {
    display: inline-block;
    padding-bottom: 10px;

    &.is_row {
        padding: 10px;
        display: block;
        border-top: 1px solid rgba($black, 0.1);
    }

    .claim {
        .el-col {
            min-height: 40px;
            display: flex;
            align-items: center;

            &.right {
                justify-content: flex-end;
            }
        }

        .header .info {
            display: flex;
            flex-direction: column;
            overflow-wrap: break-word;
            max-width: 100%;

            span {
                font-size: 11px;
                margin-bottom: 5px;
                max-width: 100%;
            }

            .title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

            .number {
                margin-left: 12px;
                font-weight: 500;
                margin-bottom: 0;
                margin-top: 2px;
                font-size: 12px;
            }
        }

        .budget {
            font-family: 'Rubik';
            font-size: 16px;
            color: $black;
            margin-right: 20px;
        }

        .el-button {
            width: 120px;
        }
    }

    .new {
        min-height: 40px;

        label {
            text-align: left;
            vertical-align: middle;
            font-size: 14px;
            color: #606266;
            padding: 0 12px 0 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: block;
        }

        .el-button {
            float: right;
            width: 120px;
        }
    }

    .no-attachment {
        opacity: 0.1;
    }
}
.attachment-label {
    font-size: 11px;
    color: #606266;
}

.rejected {
    .info {
        opacity: 0.3;
    }
    .budget {
        opacity: 0.3;
    }
}
</style>
