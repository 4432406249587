var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.total_core_supports !== false
    ? _c("div", { staticClass: "total-core-supports-block" }, [
        _c("span", { staticClass: "label" }, [_vm._v("Total Core supports")]),
        _c("span", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm._f("currency")(_vm.total_core_supports))),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }