<template>
    <div>
        <div class="plan-column">
            <div class="plan-header">
                <plan-summary
                    :plan_id="selected_plan_id"
                    :plans="ordered_plans"
                    :read_only="readOnly"
                    :can_delete="can_delete_plans"
                    :generating="generating"
                    :creating_new_plan="creating_new_plan"
                    @export="planStatementDialogVisible = true"
                    @select="selectPlan"
                    @creating="toggleCreatingPlan"
                    @create="createPlan"
                    @update="updatePlan"
                    @delete="deletePlanDialogVisible = true"
                />

                <div
                    v-if="
                        can_see_comments && selected_plan && !creating_new_plan
                    "
                    class="comments-section"
                >
                    <plan-comments-block :plan="selected_plan" />
                </div>

                <total-core-supports-block :budget_data="data" />

                <div
                    v-if="user.require_approval && readOnly"
                    class="approval-notice"
                >
                    Approval is required for all claims
                </div>
            </div>

            <plan-budgets
                v-if="selected_plan && !creating_new_plan"
                :user="user"
                :plan_id="selected_plan.id"
                :budgets="data"
                :read_only="readOnly"
            />
        </div>

        <el-dialog
            class="statement-dialog"
            title="Export Statement"
            :visible.sync="planStatementDialogVisible"
            width="30%"
            center
        >
            <p>Select the categories to include in this statement</p>
            <div class="select-all">
                <el-checkbox
                    v-model="all_categories_selected"
                    :indeterminate="
                        selected_categories.length > 0 &&
                        selected_categories.length < used_categories.length
                    "
                    @change="toggleSelectCategories"
                >
                    All
                </el-checkbox>
            </div>
            <el-checkbox-group v-model="selected_categories">
                <ul class="selected-categories-list">
                    <li v-for="category in used_categories" :key="category.id">
                        <el-checkbox :label="category.id">{{
                            category.name
                        }}</el-checkbox>
                    </li>
                </ul>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
                <span
                    ><el-button @click="planStatementDialogVisible = false">
                        Cancel
                    </el-button>
                </span>
                <el-button
                    :disabled="selected_categories.length === 0"
                    @click="generatePDFStatement()"
                >
                    <i class="el-icon-document" /> PDF
                </el-button>
                <el-button
                    :disabled="selected_categories.length === 0"
                    @click="generateCSVStatement()"
                >
                    <i class="el-icon-document" /> CSV
                </el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="Warning"
            :visible.sync="deletePlanDialogVisible"
            width="30%"
            center
        >
            <p>
                Delete plan
                <b>{{ planDatesName(selected_plan) }}</b>
                ?
            </p>
            <p>This plan and all related data will be permanently deleted.</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deletePlanDialogVisible = false">
                    Cancel
                </el-button>
                <el-button type="danger" @click="deletePlan()">
                    Delete
                </el-button>
            </span>
        </el-dialog>

        <budget-modal
            v-if="selected_plan"
            :plan_id="selected_plan.id"
            :categories="available_categories"
        />
        <claim-modal :categories="used_categories" :data="data" :user="user" />
    </div>
</template>

<script>
import PlanSummary from '@/views/components/PlanSummary';
import PlanBudgets from '@/views/components/PlanBudgets';
import ClaimModal from '@/views/components/ClaimModal';
import PlanCommentsBlock from '@/views/components/PlanCommentsBlock';
import TotalCoreSupportsBlock from '@/views/components/TotalCoreSupportsBlock';

import {PDFDocument, rgb, StandardFonts, PageSizes} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import robotoFile from '@/assets/fonts/Roboto-Regular.ttf';
import robotoBoldFile from '@/assets/fonts/Roboto-Bold.ttf';
import logoFile from '@/assets/logo_print_colour.png';

import {mixin as clickaway} from 'vue-clickaway';
import moment from 'moment';
import firebase from 'firebase/app';
import auth from '@/utils/auth';
import BudgetModal from '@/views/components/BudgetModal';
import * as Sentry from '@sentry/browser';

export default {
    name: 'plan-panel',
    components: {
        PlanSummary,
        PlanBudgets,
        BudgetModal,
        ClaimModal,
        PlanCommentsBlock,
        TotalCoreSupportsBlock,
    },
    mixins: [clickaway],
    props: {
        user: {
            type: Object,
            default: null,
        },
        readOnly: {
            type: Boolean,
        },
        showName: {
            type: Boolean,
        },
    },
    data() {
        let vm = this;
        return {
            creating_new_plan: false,
            raw_categories: [],
            selected_plan_id: null,
            new_plan_range: null,
            rangeOptions: {
                disabledDate(time) {
                    let disabled = false;
                    let compareDate = moment(time);

                    if (!vm.plans) return false;
                    const plans =
                        vm.selected_plan && !vm.creating_new_plan
                            ? vm.plans.filter((plan) => {
                                  return plan.id !== vm.selected_plan.id;
                              })
                            : vm.plans;

                    plans.some((plan) => {
                        if (
                            (typeof plan === 'object' ||
                                plan instanceof Object) &&
                            plan.start &&
                            plan.start.seconds &&
                            plan.expiry.seconds
                        ) {
                            let startDate = moment
                                .unix(plan.start.seconds)
                                .subtract(1, 'day');
                            let endDate = moment
                                .unix(plan.expiry.seconds)
                                .add(1, 'day');

                            disabled = compareDate.isBetween(
                                startDate,
                                endDate
                            );
                            return disabled;
                        } else return null;
                    });

                    return disabled;
                },
            },

            loading: false,
            deletePlanDialogVisible: false,
            planStatementDialogVisible: false,
            selected_categories: [],
            all_categories_selected: false,

            generating: null, // null: not activated yet - true: processing PDF - false: finished processing
        };
    },
    computed: {
        plans() {
            if (!this.user.plans) return [];
            return this.user.plans.filter((plan) => {
                return !(typeof plan === 'string' || plan instanceof String);
            });
        },
        selected_plan() {
            if (!this.selected_plan_id) return null;

            return this.plans.find((plan) => {
                return plan.id === this.selected_plan_id;
            });
        },
        data() {
            if (
                !this.selected_plan ||
                (this.selected_plan && !this.selected_plan.data) ||
                this.raw_categories.length === 0
            ) {
                return [];
            }

            let data = [...this.selected_plan.data];

            return data.sort((a, b) => {
                const a_order = this.raw_categories.find((cat) => {
                    return `categories/${cat.id}` === a.category;
                }).order;

                const b_order = this.raw_categories.find((cat) => {
                    return `categories/${cat.id}` === b.category;
                }).order;

                return a_order - b_order;
            });
        },
        available_categories() {
            const categories = this.data.map((item) => {
                return item.category;
            });

            return this.raw_categories
                .filter((raw_cat) => {
                    return (
                        raw_cat.hidden !== true &&
                        !categories.includes(`categories/${raw_cat.id}`)
                    );
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
        },
        used_categories() {
            const categories = this.data.map((item) => {
                return item.category;
            });
            return this.raw_categories
                .filter((raw_cat) => {
                    return categories.includes(`categories/${raw_cat.id}`);
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
        },
        ordered_plans() {
            const errors = this.plans.filter((p) => !p.start || !p.expiry);
            const sorted = this.plans
                .filter((p) => p.start)
                .sort((a, b) => {
                    return (
                        moment.unix(a.start.seconds) -
                        moment.unix(b.start.seconds)
                    );
                });

            return [...errors, ...sorted];
        },
        active_plan() {
            return this.$options.filters.getActivePlan(this.plans);
        },
        is_selected_plan_active() {
            if (
                this.selected_plan_id !== null &&
                this.selected_plan_id === this.active_plan?.id
            ) {
                return true;
            }
            return false;
        },
        last_expired_plan() {
            return this.$options.filters.getLastExpiredPlan(this.plans);
        },
        is_plan_expired() {
            if (!this.selected_plan) return false;
            return moment().isAfter(
                moment.unix(this.selected_plan.expiry.seconds).add(1, 'day')
            );
        },
        can_see_comments() {
            return (
                auth.role === auth.ROLE_ADMIN ||
                auth.role === auth.ROLE_SUPERADMIN
            );
        },
        can_delete_plans() {
            return auth.role === auth.ROLE_SUPERADMIN;
        },
    },
    watch: {
        plans() {
            this.updateSelectedPlan();
        },
        used_categories: {
            handler(newVal, oldVal) {
                // if the number of categories in this plan changes, select all categories by default
                if (newVal && (!oldVal || newVal.length !== oldVal.length)) {
                    this.selected_categories = newVal.map((c) => c.id);
                }
            },
            immediate: true,
        },
        selected_categories(val) {
            if (val && val.length === this.used_categories.length) {
                this.all_categories_selected = true;
            } else {
                this.all_categories_selected = false;
            }
        },
    },
    mounted() {
        this.updateSelectedPlan();

        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );
    },
    methods: {
        toggleSelectCategories(selected) {
            if (selected) {
                this.selected_categories = this.used_categories.map(
                    (c) => c.id
                );
            } else {
                this.selected_categories = [];
            }
        },

        toggleCreatingPlan(state) {
            this.creating_new_plan = state;
        },

        selectPlan(plan_id) {
            this.selected_plan_id = plan_id;
        },

        async createPlan(new_plan_range) {
            if (new_plan_range && new_plan_range.length === 2) {
                this.loading = true;

                let data = {
                    start: new_plan_range[0],
                    expiry: new_plan_range[1],
                };

                const plan = await this.$fire.collection('plans').add(data);

                await this.$fire.doc(`users/${this.user.id}`).update({
                    plans: firebase.firestore.FieldValue.arrayUnion(
                        this.$fire.doc(`plans/${plan.id}`)
                    ),
                });

                this.validateActivePlan();
                this.selected_plan_id = plan.id;

                this.creating_new_plan = false;
                this.editing = false;
                this.loading = false;
            }
        },
        async updatePlan(plan_id, updated_range) {
            if (updated_range && updated_range.length === 2) {
                this.loading = true;

                let data = {
                    start: updated_range[0],
                    expiry: updated_range[1],
                };

                await this.$fire.doc(`plans/${plan_id}`).update(data);
            }

            this.validateActivePlan();

            this.editing = false;
            this.loading = false;
        },
        async deletePlan() {
            // Set plan as deleted
            await this.$fire.doc(`plans/${this.selected_plan.id}`).update({
                user: this.$fire.doc(`users/${this.user.id}`),
                deleted: true,
            });

            // Remove plan ref from user
            await this.$fire.doc(`users/${this.user.id}`).update({
                plans: firebase.firestore.FieldValue.arrayRemove(
                    this.$fire.doc(`plans/${this.selected_plan.id}`)
                ),
            });

            this.deletePlanDialogVisible = false;
            this.editing = false;
            this.updateSelectedPlan();

            this.creating_new_plan = false;
        },
        isExpired(plan) {
            if (!plan.expiry) return true;
            return moment().isAfter(moment.unix(plan.expiry.seconds));
        },
        isFuture(plan) {
            if (!plan.start) return false;
            return moment.unix(plan.start.seconds).isAfter(moment());
        },
        updateSelectedPlan() {
            // don't change selected plan if the delete dialog is open!
            if (this.deletePlanDialogVisible) return;

            if (this.active_plan) {
                this.selected_plan_id = this.active_plan.id;
            } else if (this.last_expired_plan) {
                this.selected_plan_id = this.last_expired_plan.id;
            } else if (this.plans && this.plans.length > 0) {
                this.selected_plan_id = this.plans[0].id;
            } else {
                this.selected_plan_id = null;
            }
        },
        async validateActivePlan() {
            const cache = await this.$fire
                .collection('expired_cache')
                .doc(this.user.id)
                .get();

            if (this.active_plan) {
                // if this user is in the expired cache, remove from cache
                if (cache.exists) {
                    await cache.ref.delete();
                }
            } else {
                // set user's details in cache
                const expired_on = this.last_expired_plan.expiry.toDate
                    ? this.last_expired_plan.expiry.toDate()
                    : this.last_expired_plan.expiry;
                await cache.ref.set({
                    name: this.user.name,
                    code: this.user.code,
                    expired_on,
                    plan: this.$fire.doc(`plans/${this.last_expired_plan.id}`),
                });
            }
        },
        planDatesName(tmp_plan) {
            if (!tmp_plan) return '';

            const format = 'MMM D, YYYY';
            const start = tmp_plan.start
                ? `${this.$options.filters.dateformat(
                      this.$options.filters.timestamp2Date(
                          tmp_plan.start.seconds
                      ),
                      format
                  )}`
                : '*';
            const expiry = tmp_plan.expiry
                ? `${this.$options.filters.dateformat(
                      this.$options.filters.timestamp2Date(
                          tmp_plan.expiry.seconds
                      ),
                      format
                  )}`
                : '*';
            return `[ ${start} - ${expiry} ]`;
        },

        /* Generate PDF statement for this user */

        async generatePDFStatement() {
            this.planStatementDialogVisible = false;
            if (this.generating) return;
            this.generating = true;
            const pdfDoc = await PDFDocument.create();
            pdfDoc.registerFontkit(fontkit);

            // get fonts
            let roboto = null;
            let robotoBold = null;
            try {
                const robotoBytes = await fetch(robotoFile).then((res) =>
                    res.arrayBuffer()
                );
                roboto = await pdfDoc.embedFont(robotoBytes);

                const robotoBoldBytes = await fetch(robotoBoldFile).then(
                    (res) => res.arrayBuffer()
                );
                robotoBold = await pdfDoc.embedFont(robotoBoldBytes);
            } catch (err) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Unable to generate PDF at this time',
                });
                Sentry.captureException(err);
                return;
            }

            const ink = rgb(0, 0, 0);
            const format = {size: 9, color: ink, font: roboto};
            const formatBold = {size: 9, color: ink, font: robotoBold};
            const formatHeader = {size: 14, color: ink, font: robotoBold};

            const logoData = await fetch(logoFile).then((res) =>
                res.arrayBuffer()
            );
            const logo = await pdfDoc.embedPng(logoData);
            const logoDims = logo.scale(0.4);

            const formatter = new Intl.NumberFormat('en-AU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            let approvals = false;

            pdfDoc.setTitle(
                `TP Records - Statement for ${this.user.name} (${this.user.code})`
            );

            let page = pdfDoc.addPage(PageSizes.A4); // 595.28 x 841.89

            // TP Records address block
            page.drawImage(logo, {
                x: 40,
                y: 788,
                width: logoDims.width,
                height: logoDims.height,
            });
            page.drawText('PO Box 259, Ryde NSW 1680', {
                ...format,
                x: 40,
                y: 774,
            });
            page.drawText('tatum@tprecords.com.au', {...format, x: 40, y: 762});
            page.drawText('0432 790 295', {...format, x: 40, y: 750});

            // client address block
            page.drawText('Client Name:', {...format, x: 40, y: 710});
            page.drawText('NDIS Number:', {...format, x: 40, y: 698});
            page.drawText('Start Date of Plan:', {...format, x: 40, y: 686});
            page.drawText('Expiry Date of Plan:', {...format, x: 40, y: 674});

            page.drawText(this.user.name, {...formatBold, x: 140, y: 710});
            page.drawText(this.user.ndis_number, {
                ...formatBold,
                x: 140,
                y: 698,
            });
            page.drawText(
                moment
                    .unix(this.selected_plan.start.seconds)
                    .format('D MMMM YYYY'),
                {...format, x: 140, y: 686}
            );
            page.drawText(
                moment
                    .unix(this.selected_plan.expiry.seconds)
                    .format('D MMMM, YYYY'),
                {...format, x: 140, y: 674}
            );

            let pos = 634; // starting point after addresses
            const moveDown = (increment) => {
                if (pos - increment < 50) {
                    // too low, reset to top of new page
                    page = pdfDoc.addPage(PageSizes.A4);
                    pos = 790;
                } else {
                    pos -= increment;
                }
            };

            let totalAllocated = 0;
            let totalSpent = 0;

            // budget blocks

            const planBudgetData = this.data.filter((item) => {
                const categoryId = item.category.split('/').pop();
                // don't include budgets that are not a selected category
                return this.selected_categories.includes(categoryId);
            });

            for (const item of planBudgetData) {
                // get budget record
                const category = await this.$fire
                    .doc(item.category)
                    .get()
                    .then((snapshot) => snapshot.data());

                if (
                    !this.selected_categories.find((c) => c.id === category.id)
                ) {
                    // don't process this budget
                    continue;
                }

                const budget = await this.$fire
                    .doc(item.budget)
                    .get()
                    .then((snapshot) => snapshot.data());

                totalAllocated += budget.budget_allocated;

                // budget details
                page.drawText(category.name, {...formatHeader, x: 40, y: pos});
                moveDown(30);
                page.drawText('Budget Allocated', {...format, x: 40, y: pos});
                page.drawText('$', {...format, x: 490, y: pos});
                const bAlloc = formatter.format(budget.budget_allocated);
                page.drawText(bAlloc, {
                    ...format,
                    x: 550 - roboto.widthOfTextAtSize(bAlloc, 9),
                    y: pos,
                });
                moveDown(20);

                let budgetTotal = 0;
                let odd = true;

                // budget claims
                if (budget.claims && budget.claims.length > 0) {
                    const claims = [];
                    // retrieve claims data
                    for (let c = 0; c < budget.claims.length; c++) {
                        const claim = await budget.claims[c]
                            .get()
                            .then((snapshot) => snapshot.data());
                        if (claim.approval_status !== 'rejected') {
                            claims.push(claim);
                        }
                    }
                    // sort by date
                    claims.sort((a, b) => {
                        return a.date.seconds - b.date.seconds;
                    });
                    // loop over sorted claims
                    claims.forEach((claim) => {
                        const pending_claim =
                            this.user.require_approval &&
                            claim.approval_status !== 'approved' &&
                            claim.approval_due &&
                            moment().isBefore(
                                moment.unix(claim.approval_due.seconds)
                            );
                        if (pending_claim) approvals = true;

                        if (!claim) return;
                        // check for if long fields have multiple lines
                        let provider = this.pdfTextLineBreak(
                            roboto,
                            9,
                            claim.provider,
                            210
                        );
                        let invoice = this.pdfTextLineBreak(
                            roboto,
                            9,
                            claim.invoice_number,
                            120
                        );
                        const lineHeight =
                            Math.max(provider.length, invoice.length, 1) * 12;

                        budgetTotal += claim.total;

                        // highlight alternating rows
                        page.drawRectangle({
                            x: 40,
                            y: pos - 3.2 - (lineHeight - 12),
                            width: 510,
                            height: lineHeight,
                            color: rgb(0, 0, 0),
                            opacity: odd ? 0.05 : 0,
                        });

                        odd = !odd;

                        page.drawText(
                            moment.unix(claim.date.seconds).format('D MMM YY'),
                            {...format, x: 40, y: pos}
                        );
                        for (let line = 0; line < provider.length; line++) {
                            page.drawText(provider[line], {
                                ...format,
                                x: 140,
                                y: pos - line * 12,
                            });
                        }
                        for (let line = 0; line < invoice.length; line++) {
                            page.drawText(invoice[line], {
                                ...format,
                                x: 360,
                                y: pos - line * 12,
                            });
                        }
                        if (pending_claim)
                            page.drawText('*', {...formatBold, x: 480, y: pos});
                        page.drawText('$', {...format, x: 490, y: pos});
                        let cTotal = formatter.format(claim.total);
                        page.drawText(cTotal, {
                            ...format,
                            x: 550 - roboto.widthOfTextAtSize(cTotal, 9),
                            y: pos,
                        });
                        moveDown(lineHeight);
                    });
                    moveDown(6);
                }
                totalSpent += budgetTotal;

                page.drawText('Total Spent', {
                    ...formatBold,
                    x: 40,
                    y: pos,
                });
                page.drawText('$', {...formatBold, x: 490, y: pos});
                const bTotal = formatter.format(budgetTotal);
                page.drawText(bTotal, {
                    ...format,
                    x: 550 - roboto.widthOfTextAtSize(bTotal, 9),
                    y: pos,
                });
                moveDown(16);

                page.drawText('Balance Left', {
                    ...formatBold,
                    x: 40,
                    y: pos,
                });
                page.drawText('$', {...formatBold, x: 490, y: pos});
                const bLeft = formatter.format(
                    budget.budget_allocated - budgetTotal
                );
                page.drawText(bLeft, {
                    ...formatBold,
                    x: 550 - robotoBold.widthOfTextAtSize(bLeft, 9),
                    y: pos,
                });
                moveDown(10);

                page.drawLine({
                    start: {x: 40, y: pos},
                    end: {x: 550, y: pos},
                    thickness: 1,
                    color: rgb(0, 0, 0),
                    opacity: 0.5,
                });

                moveDown(40);
            }

            page.drawText('Total Budget Allocated', {
                ...formatBold,
                x: 40,
                y: pos,
            });
            page.drawText('$', {...format, x: 490, y: pos});
            const tAlloc = formatter.format(totalAllocated);
            page.drawText(tAlloc, {
                ...format,
                x: 550 - roboto.widthOfTextAtSize(tAlloc, 9),
                y: pos,
            });
            moveDown(16);

            page.drawText('Total Budget Spent', {
                ...formatBold,
                x: 40,
                y: pos,
            });
            page.drawText('$', {...format, x: 490, y: pos});
            const tSpent = formatter.format(totalSpent);
            page.drawText(tSpent, {
                ...format,
                x: 550 - roboto.widthOfTextAtSize(tSpent, 9),
                y: pos,
            });
            moveDown(16);

            page.drawText('Total Budget Left', {
                ...formatBold,
                x: 40,
                y: pos,
            });
            page.drawText('$', {...formatBold, x: 490, y: pos});
            const tLeft = formatter.format(totalAllocated - totalSpent);
            page.drawText(tLeft, {
                ...formatBold,
                x: 550 - roboto.widthOfTextAtSize(tLeft, 9),
                y: pos,
            });

            if (approvals) {
                moveDown(32);
                page.drawText('* Claims pending client approval', {
                    ...format,
                    x: 40,
                    y: pos,
                });
            }

            // number the pages
            const pages = pdfDoc.getPages();
            pages.forEach((p, i) => {
                const pNum = `${i + 1} / ${pages.length}`;
                p.drawText(pNum, {
                    ...format,
                    x:
                        (PageSizes.A4[0] - roboto.widthOfTextAtSize(pNum, 9)) /
                        2,
                    y: 35,
                });
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            const pdfBlob = new Blob([pdfBytes], {type: 'application/pdf'});
            const pdfURL = URL.createObjectURL(pdfBlob);

            // create dummy link to trigger download with filename
            let link = document.createElement('a');
            link.href = pdfURL;
            link.download = `TPR-${this.user.code}-${moment().format(
                'YYYYMMDD'
            )}.pdf`;
            link.click();

            // // or, open in new window (no filename set)
            // window.open(pdfURL, '_blank');

            this.generating = false;
        },

        /* break sourceText that would render wider than maxWidth into multiple lines */
        pdfTextLineBreak(
            font,
            size,
            sourceText,
            maxWidth,
            splitOnChars = false
        ) {
            // split on words or individual characters
            let splitChar = splitOnChars ? '' : ' ';
            let result = [];
            if (sourceText) {
                let currentLine = sourceText;
                let remaining = '';

                while (currentLine) {
                    let words = currentLine.split(splitChar);
                    // split words if required (results in a recursive call)
                    words = this.pdfWordBreak(font, size, words, maxWidth);

                    currentLine = words.join(' ');

                    let width = font.widthOfTextAtSize(currentLine, size);
                    let count = 0;
                    // work backwards through word array to find the longest line that will fit
                    for (
                        let wordBreak = words.length;
                        wordBreak >= 0;
                        wordBreak--
                    ) {
                        count++;
                        if (count > 100) break;

                        currentLine = words.slice(0, wordBreak).join(splitChar);
                        remaining = words.slice(wordBreak).join(splitChar);
                        let width = font.widthOfTextAtSize(currentLine, size);
                        if (width <= maxWidth) break;
                    }
                    result.push(currentLine);
                    currentLine = remaining;
                    remaining = '';
                }
            }
            return result;
        },

        /* split words in array that would render wider than maxWidth */
        pdfWordBreak(font, size, words, maxWidth) {
            let result = [];
            for (let w = 0; w < words.length; w++) {
                let currentWord = words[w];
                let remaining = '';

                let width = font.widthOfTextAtSize(currentWord, size);
                if (width > maxWidth) {
                    // split this word into separate words that fit within maxWidth
                    let splitWord = this.pdfTextLineBreak(
                        font,
                        size,
                        currentWord,
                        maxWidth,
                        true
                    );
                    result = [...result, ...splitWord];
                } else {
                    result.push(currentWord);
                }
            }
            return result;
        },

        /* Generate CSV statement for this user */

        async generateCSVStatement() {
            this.planStatementDialogVisible = false;

            let rows = ['"Category","Date","Provider","Invoice #","Total"'];

            const planBudgetData = this.data.filter((item) => {
                const categoryId = item.category.split('/').pop();
                // don't include budgets that are not a selected category
                return this.selected_categories.includes(categoryId);
            });

            for (const item of planBudgetData) {
                const category = await this.$fire
                    .doc(item.category)
                    .get()
                    .then((snapshot) => snapshot.data());

                if (
                    !this.selected_categories.find((c) => c.id === category.id)
                ) {
                    // don't process this budget
                    continue;
                }

                const budget = await this.$fire
                    .doc(item.budget)
                    .get()
                    .then((snapshot) => snapshot.data());

                if (budget.claims && budget.claims.length > 0) {
                    const claims = [];
                    // retrieve claims data
                    for (let c = 0; c < budget.claims.length; c++) {
                        const claim = await budget.claims[c]
                            .get()
                            .then((snapshot) => snapshot.data());
                        if (claim.approval_status !== 'rejected') {
                            claims.push(claim);
                        }
                    }
                    // sort by date
                    claims.sort((a, b) => {
                        return a.date.seconds - b.date.seconds;
                    });
                    // loop over sorted claims
                    claims.forEach((claim) => {
                        const row = [
                            `"${category.name.replace('"', '""')}"`,
                            `"${moment
                                .unix(claim.date.seconds)
                                .format('YYYY-MM-DD')}"`,
                            `"${claim.provider.replace('"', '""')}"`,
                            `"${
                                claim.invoice_number?.replace('"', '""') ?? ''
                            }"`,
                            claim.total,
                        ];
                        rows.push(row.join(','));
                    });
                }
            }

            const filename = `${this.user.ndis_number}_${
                this.user.code
            }_${moment().format('YYYYMMDD')}.csv`;

            const csvData = rows.join('\n');

            // trigger download of CSV data
            const element = document.createElement('a');
            element.setAttribute(
                'href',
                'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
            );
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
    },
};
</script>

<style lang="scss" scoped>
.create-plan-col {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.actions-col {
    height: 100px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.plan-select {
    width: 100%;
    margin-bottom: 20px;
}

.create-plan {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .el-button {
        min-width: 100px;
    }
}

.plan-type {
    float: right;
    color: #8492a6;
    font-size: 13px;
}

.plan-column {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 70px);
    .plan-header {
        background-color: #f0f2f5;
        z-index: 999;
        top: 0px;
        padding-bottom: 20px;
        padding-top: 5px;

        label {
            text-align: left;
            vertical-align: middle;
            font-size: 14px;
            color: #606266;
            line-height: 40px;
            padding: 0 12px 0 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .expiry-date {
            display: block;
            background-color: transparent;
        }

        .el-date-editor {
            width: 100%;
        }

        .comments-section {
            background: white;
            border-radius: 8px;
            padding: 10px;
            margin-top: 20px;
        }
    }
}

.el-alert {
    margin: 20px 0;
    border: 1px solid $red;
    padding: 15px 20px;
}

.statement-dialog {
    .select-all {
        margin-left: 40px;
        border-bottom: 1px solid $grey;
        margin-bottom: 10px;
    }
    .selected-categories-list {
        overflow: auto;
    }
    .dialog-footer {
        display: flex;
        > *:first-child {
            text-align: left;
            flex: 1;
        }
    }
}

.approval-notice {
    text-align: center;
    font-size: 14px;
    color: $blue;
}
</style>
