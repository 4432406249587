var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "comment" }, [
    _c("div", { staticClass: "header" }, [
      _vm.comment.posted_by
        ? _c(
            "span",
            {
              staticClass: "user",
              class: { deleted: _vm.comment.posted_by.deleted_at },
            },
            [_vm._v(" " + _vm._s(_vm.comment.posted_by.name) + " ")]
          )
        : _vm._e(),
      _c("span", { staticClass: "time" }, [_vm._v(_vm._s(_vm.display_date))]),
    ]),
    _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.comment.text))]),
    _vm.can_delete
      ? _c("div", { staticClass: "delete-button" }, [
          _c("i", {
            staticClass: "el-icon-circle-close",
            on: {
              click: function ($event) {
                return _vm.deleteComment(_vm.comment)
              },
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }