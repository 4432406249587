var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "claim-block",
      class: { is_row: !!_vm.claim, rejected: _vm.isRejected },
    },
    [
      _vm.claim
        ? _c(
            "div",
            { staticClass: "claim" },
            [
              _c(
                "el-row",
                { staticClass: "header", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "center", attrs: { span: 1 } },
                    [
                      _vm.claim.attachments?.length
                        ? _c(
                            "el-popover",
                            { attrs: { placement: "top", trigger: "hover" } },
                            [
                              _c("span", { staticClass: "attachment-label" }, [
                                _vm._v("Attachments"),
                              ]),
                              _c(
                                "div",
                                _vm._l(
                                  _vm.claim.attachments,
                                  function (attachment) {
                                    return _c("attachment", {
                                      key: attachment.path,
                                      attrs: {
                                        name: attachment.name,
                                        path: attachment.path,
                                        readonly: "",
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c("i", {
                                staticClass: "attachment",
                                class:
                                  _vm.claim.attachments.length > 1
                                    ? "el-icon-document-copy"
                                    : "el-icon-document",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              }),
                            ]
                          )
                        : _c("i", {
                            staticClass:
                              "no-attachment el-icon-document-delete",
                          }),
                    ],
                    1
                  ),
                  _vm.user.require_approval
                    ? _c(
                        "el-col",
                        { staticClass: "center", attrs: { span: 1 } },
                        [_c("approval-icon", { attrs: { claim: _vm.claim } })],
                        1
                      )
                    : _vm._e(),
                  _c("el-col", { attrs: { span: _vm.detail_width } }, [
                    _c("div", { staticClass: "info" }, [
                      _vm.claim.date
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateformat")(
                                    _vm._f("timestamp2Date")(
                                      _vm.claim.date.seconds
                                    ),
                                    "MMM D, YYYY"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "title" }, [
                        _vm.claim.provider
                          ? _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("capitalize")(_vm.claim.provider)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.claim.invoice_number
                          ? _c("span", { staticClass: "number" }, [
                              _vm._v(
                                " [ " + _vm._s(_vm.claim.invoice_number) + " ] "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm.canApprove && _vm.user.require_approval && _vm.isPending
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("approval-action", {
                            attrs: { claim: _vm.claim },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { staticClass: "right", attrs: { span: 7 } },
                    [
                      _c("span", { staticClass: "budget" }, [
                        _vm._v(_vm._s(_vm._f("currency")(_vm.claim.total))),
                      ]),
                      !_vm.freeze
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showModal()
                                },
                              },
                            },
                            [_vm._v(" View ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "new" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.showModal()
                    },
                  },
                },
                [_vm._v(" New claim ")]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }