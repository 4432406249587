<template>
    <li class="comment">
        <div class="header">
            <span
                v-if="comment.posted_by"
                class="user"
                :class="{deleted: comment.posted_by.deleted_at}"
            >
                {{ comment.posted_by.name }}
            </span>
            <span class="time">{{ display_date }}</span>
        </div>
        <span class="text">{{ comment.text }}</span>
        <div v-if="can_delete" class="delete-button">
            <i class="el-icon-circle-close" @click="deleteComment(comment)" />
        </div>
    </li>
</template>

<script>
import firebase from 'firebase/app';
import auth from '@/utils/auth';
import moment from 'moment';

export default {
    name: 'comment-row',
    props: {
        comment: {
            type: Object,
            required: true,
        },
    },
    computed: {
        is_my_comment() {
            return (
                this.comment.posted_by &&
                this.comment.posted_by.id === firebase.auth().currentUser.uid
            );
        },
        can_delete() {
            return (
                auth.role === auth.ROLE_SUPERADMIN ||
                this.is_my_comment ||
                auth.userData.can_manage_comments
            );
        },
        display_date() {
            return moment(this.comment.date.toDate()).format('DD/MM/YY');
        },
    },
    methods: {
        deleteComment(comment) {
            this.$emit('delete', comment);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.comment {
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid rgba($black, 0.04);

    .header {
        display: flex;
        flex-direction: column;
        width: 80px;
    }

    span {
        font-family: 'Rubik';
    }

    .user {
        font-size: 10px;
        &.deleted {
            text-decoration: line-through;
        }
    }
    .time {
        font-size: 9px;
        opacity: 0.6;
    }

    .text {
        flex: 1;
        font-size: 14px;
        white-space: pre-line;
    }

    .delete-button {
        cursor: pointer;
        padding: 0 5px;
        i:not(:hover) {
            color: #a0a0a0;
        }
    }
}
</style>
