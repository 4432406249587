<template>
    <div class="approval-icon">
        <i v-if="isPending" class="pending el-icon-warning" />
        <i v-else-if="isRejected" class="rejected el-icon-circle-close" />
        <i v-else-if="isApproved" class="approved el-icon-circle-check" />
    </div>
</template>

<script>
import moment from 'moment/moment';
import claimMixin from '@/mixins/claim.mixin';
export default {
    name: 'approval-icon',
    mixins: [claimMixin],
    props: {
        claim: {
            type: [String, Object],
            default: null,
        },
    },
    computed: {
        approvalDeadline() {
            if (!this.claim.approval_due) return null;
            return moment.unix(this.claim.approval_due.seconds);
        },
        isClaim() {
            return !!this.claim?.id;
        },
        isPending() {
            return (
                this.isClaim &&
                this.approvalDeadline !== null &&
                //moment().isBefore(this.approvalDeadline) && // TODO to be handled by cloud func
                this.claim.approval_status !== 'approved' &&
                this.claim.approval_status !== 'rejected'
            );
        },
        isApproved() {
            return (
                this.isClaim &&
                (this.approvalDeadline === null ||
                    moment().isSameOrAfter(this.approvalDeadline) ||
                    this.claim.approval_status === 'approved')
            );
        },
        isRejected() {
            return this.isClaim && this.claim.approval_status === 'rejected';
        },
    },
};
</script>

<style lang="scss" scoped>
.approval-icon {
    font-size: 20px;
    .approved {
        color: $green;
    }
    .pending {
        color: $yellow;
    }
    .rejected {
        color: $red;
    }
}
</style>
