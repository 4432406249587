<template>
    <div v-loading="loading" class="plan-summary">
        <div class="plan-selector">
            <h3 class="plan-header">NDIS Plan</h3>

            <div v-if="selected_plan && !editing_plan && !creating_new_plan">
                <el-select
                    v-model="selected_plan_id"
                    placeholder="Select"
                    value-key="expiry"
                    class="plan-select"
                    popper-class="plan-select-dropdown"
                >
                    <el-option
                        v-for="tmp_plan in plans"
                        :key="tmp_plan.id"
                        :label="planDatesName(tmp_plan)"
                        :value="tmp_plan.id"
                        :disabled="!tmp_plan.start || !tmp_plan.expiry"
                    >
                        <span>{{ planDatesName(tmp_plan) }}</span>
                        <span
                            v-if="active_plan && tmp_plan.id === active_plan.id"
                            class="plan-type"
                        >
                            Active
                        </span>
                        <span v-else-if="isExpired(tmp_plan)" class="plan-type">
                            Expired
                        </span>
                        <span v-else-if="isFuture(tmp_plan)" class="plan-type">
                            Future
                        </span>
                    </el-option>
                    <el-option
                        v-if="!read_only"
                        disabled
                        :value="null"
                        class="create-plan-row"
                        ><el-button
                            type="primary"
                            plain
                            size="medium"
                            class="create-plan-button"
                            @click="handleClickCreate"
                            >Create new plan</el-button
                        ></el-option
                    >
                </el-select>
                <el-button class="download" @click="$emit('export')">
                    <i v-if="generating === true" class="el-icon-loading" />
                    <i v-else-if="generating === false" class="el-icon-check" />
                    <i v-else class="el-icon-document" /> Export statement
                </el-button>
                <template v-if="!read_only">
                    <el-button class="edit-button" @click="handleClickEdit">
                        Edit
                    </el-button>
                    <el-button
                        v-if="can_delete"
                        type="danger"
                        style="margin-left: 5px"
                        :disabled="is_selected_plan_active"
                        @click="handleClickDelete"
                    >
                        <i class="el-icon-delete" />
                    </el-button>
                </template>
            </div>
            <div v-if="editing_plan">
                <el-button @click="cancelEdit"> Cancel </el-button>
                <el-button
                    type="primary"
                    :loading="loading"
                    @click="updatePlan()"
                >
                    Save
                </el-button>
            </div>
            <div v-if="creating_new_plan || !plans.length">
                <el-button v-if="plans.length" @click="cancelCreate">
                    Cancel
                </el-button>
                <el-button
                    type="primary"
                    :loading="loading"
                    @click="createPlan()"
                >
                    Create
                </el-button>
            </div>
        </div>

        <div class="alert">
            <el-alert
                v-if="is_plan_expired && !editing_plan && !creating_new_plan"
                type="error"
                :closable="false"
                title="This plan is expired."
            />
            <div v-if="creating_new_plan">Select plan duration:</div>
            <div v-if="editing_plan">Select updated plan duration:</div>
        </div>

        <div
            v-if="selected_plan && !creating_new_plan && !editing_plan"
            class="budget-bar"
        >
            <div class="budget-available">
                <span class="amount">{{ budgetAvailable | currency }}</span>
                <span class="label">Available</span>
            </div>
            <progress-bar
                class="budget-spent"
                :percentage="budget_spent_percent"
                :value="budgetSpent | currency"
            />
            <div class="budget-total">
                of
                <span>{{ budgetTotal | currency }}</span>
            </div>
        </div>

        <div v-else>
            <el-date-picker
                v-model="updated_range"
                class="plan-duration-picker"
                type="daterange"
                range-separator="to"
                start-placeholder="Start date"
                end-placeholder="Expiry date"
                :picker-options="rangeOptions"
                format="MMM dd, yyyy"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment/moment';
import ProgressBar from '@/views/components/ProgressBar';

export default {
    name: 'plan-summary',
    components: {ProgressBar},
    props: {
        plan_id: {
            type: String,
            default: null,
        },
        plans: {
            type: Array,
            default: () => [],
        },
        read_only: {
            type: Boolean,
            default: false,
        },
        can_delete: {
            type: Boolean,
            default: false,
        },
        creating_new_plan: {
            type: Boolean,
            default: false,
        },
        generating: {
            // null: not activated yet - true: processing PDF - false: finished processing
            required: false,
            default: null,
            validator: (prop) => typeof prop === 'boolean' || prop === null,
        },
    },
    data() {
        let vm = this;
        return {
            selected_plan_id: null,
            editing_plan: false,
            budgets: {},
            budgetTotal: 0,
            budgetAvailable: 0,
            budgetSpent: 0,
            loading: false,
            updated_range: null,

            rangeOptions: {
                disabledDate(time) {
                    let disabled = false;
                    let compareDate = moment(time);

                    if (!vm.plans) return false;
                    const plans =
                        vm.selected_plan && !vm.creating_new_plan
                            ? vm.plans.filter((plan) => {
                                  return plan.id !== vm.selected_plan.id;
                              })
                            : vm.plans;

                    plans.some((plan) => {
                        if (
                            (typeof plan === 'object' ||
                                plan instanceof Object) &&
                            plan.start &&
                            plan.start.seconds &&
                            plan.expiry.seconds
                        ) {
                            let startDate = moment
                                .unix(plan.start.seconds)
                                .subtract(1, 'day');
                            let endDate = moment
                                .unix(plan.expiry.seconds)
                                .add(1, 'day');

                            disabled = compareDate.isBetween(
                                startDate,
                                endDate
                            );
                            return disabled;
                        } else return null;
                    });

                    return disabled;
                },
            },
        };
    },
    computed: {
        active_plan() {
            return this.$options.filters.getActivePlan(this.plans);
        },
        selected_plan() {
            if (!this.selected_plan_id) return null;

            return this.plans.find((plan) => {
                return plan.id === this.selected_plan_id;
            });
        },
        is_selected_plan_active() {
            return (
                this.selected_plan_id !== null &&
                this.selected_plan_id === this.active_plan?.id
            );
        },
        is_plan_expired() {
            if (!this.selected_plan) return false;
            return this.isExpired(this.selected_plan);
        },
        budget_spent_percent() {
            if (this.budgetAvailable > 0) {
                return (this.budgetSpent / this.budgetAvailable) * 100;
            }
            return 0;
        },
    },
    watch: {
        plan_id: {
            handler(val) {
                this.selected_plan_id = val;
            },
            immediate: true,
        },
        selected_plan() {
            this.getBudgets();
        },
        selected_plan_id(val) {
            this.$emit('select', val);
        },
    },
    mounted() {
        this.getBudgets();
    },
    methods: {
        async getBudgets() {
            this.budgets = {};
            this.budgetTotal = 0;
            this.budgetAvailable = 0;
            this.budgetSpent = 0;
            if (
                this.selected_plan &&
                this.selected_plan.data &&
                this.selected_plan.data.length
            ) {
                (this.loading = true),
                    this.selected_plan.data.forEach((data) => {
                        this.budgets[data.budget] = false;

                        this.$fire.doc(data.budget).onSnapshot((snapshot) => {
                            if (snapshot.exists)
                                this.budgets[`budgets/${snapshot.id}`] =
                                    snapshot.data();
                            else delete this.budgets[`budgets/${snapshot.id}`];

                            // check if all budgets have now been loaded
                            const budgets = Object.values(this.budgets);
                            if (!budgets.includes(false)) {
                                // TODO refactor to one reduce
                                this.budgetTotal = budgets.reduce(
                                    (a, b) => +a + +b.budget_allocated,
                                    0
                                );
                                this.budgetSpent = budgets.reduce(
                                    (a, b) => +a + +b.budget_spent,
                                    0
                                );
                                this.budgetAvailable = budgets.reduce(
                                    (a, b) =>
                                        +a +
                                        +(b.budget_allocated - b.budget_spent),
                                    0
                                );
                                this.loading = false;
                            }
                        });
                    });
            }
        },

        updatePlan() {
            this.$emit('update', this.selected_plan_id, this.updated_range);
            this.editing_plan = false;
        },

        createPlan() {
            this.$emit('create', this.updated_range);
        },

        handleClickDelete() {
            this.$emit('delete', this.selected_plan_id);
        },

        planDatesName(tmp_plan) {
            if (!tmp_plan) return '';
            const format = 'MMM D, YYYY';
            const start = tmp_plan.start
                ? `${this.$options.filters.dateformat(
                      this.$options.filters.timestamp2Date(
                          tmp_plan.start.seconds
                      ),
                      format
                  )}`
                : '*';
            const expiry = tmp_plan.expiry
                ? `${this.$options.filters.dateformat(
                      this.$options.filters.timestamp2Date(
                          tmp_plan.expiry.seconds
                      ),
                      format
                  )}`
                : '*';
            return `[ ${start} - ${expiry} ]`;
        },

        isExpired(plan) {
            if (!plan.expiry) return true;
            return moment().isAfter(moment.unix(plan.expiry.seconds));
        },
        isFuture(plan) {
            if (!plan.start) return false;
            return moment.unix(plan.start.seconds).isAfter(moment());
        },

        handleClickEdit() {
            this.updated_range = [];
            this.updated_range[0] = this.$options.filters.timestamp2Date(
                this.selected_plan.start.seconds
            );
            this.updated_range[1] = this.$options.filters.timestamp2Date(
                this.selected_plan.expiry.seconds
            );
            this.editing_plan = true;
        },
        cancelEdit() {
            this.editing_plan = false;
        },

        handleClickCreate() {
            this.$emit('creating', true);
            this.updated_range = [];
        },
        cancelCreate() {
            this.$emit('creating', false);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.plan-summary {
    background-color: white;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    height: 185px;

    .details {
        label {
            margin: 5px 0;
            display: block;
        }
    }

    .plan-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .plan-select {
        width: 250px;
        margin-right: 5px;
    }
}
.plan-type {
    float: right;
    color: #8492a6;
    font-size: 13px;
}
.create-plan-row {
    height: auto;
    padding-top: 10px;
    padding-bottom: 5px;
}
.edit-button {
    margin-left: 5px;
}
.create-plan-button {
    width: 360px;
}

.el-select-dropdown__item {
    width: 400px;
}

.alert {
    display: flex;
    height: 40px;
    align-items: center;
}
.el-alert {
    border: 1px solid $red;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.budget-bar {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;

    .budget-available {
        min-width: 120px;

        .amount {
            font-size: 24px;
            font-weight: bold;
            display: block;
        }
    }

    .budget-spent {
        flex: 1;
    }
}

.plan-duration-picker {
    width: 100%;
}
</style>
