<template>
    <div v-loading="loading" class="plan-budgets">
        <el-tabs
            v-model="selectedTab"
            type="card"
            :addable="!read_only"
            class="budget-tabs"
            @tab-add="handleAddBudget"
        >
            <el-tab-pane label="Plan Overview" name="overview">
                <budget-summary
                    v-for="entry in budgets"
                    :key="entry.budget.id"
                    :plan_id="plan.id"
                    :budget="entry.budget"
                    :category="entry.category"
                    :freeze="read_only"
                />
            </el-tab-pane>
            <el-tab-pane
                v-for="entry in budgets"
                :key="entry.budget.id"
                :label="entry.category.name"
                :name="entry.budget.id"
            >
                <budget-block
                    :user="user"
                    :plan_id="plan.id"
                    :budget="entry.budget"
                    :category="entry.category"
                    :freeze="read_only"
                />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import BudgetBlock from '@/views/components/BudgetBlock';
import BudgetSummary from '@/views/components/BudgetSummary';

export default {
    name: 'plan-budgets',
    components: {BudgetBlock, BudgetSummary},
    props: {
        user: {
            type: Object,
            required: true,
        },
        plan_id: {
            type: String,
            required: true,
        },
        read_only: Boolean,
    },
    data() {
        return {
            selectedTab: 'overview',
            plan: {},
            selectedClaim: null,
        };
    },
    computed: {
        budgets() {
            // Vuefire may not have fully populated nested values on render; only render fully loaded entries
            return (
                this.plan?.data
                    ?.filter((entry) => entry.budget?.id && entry.category?.id)
                    .sort((a, b) => a.category.order - b.category.order) ?? []
            );
        },
        loading() {
            return this.plan?.data?.length > this.budgets.length;
        },
    },
    watch: {
        plan_id: {
            // rebind plan to sufficient depth to get relevant budget & category details
            handler(val) {
                this.$bind('plan', this.$fire.doc(`/plans/${val}`), {
                    maxRefDepth: 2,
                });
            },
            immediate: true,
        },
        plan(val) {
            if (this.$route.query.claim_id !== undefined) {
                console.log(
                    'PlanBudgets: load claim',
                    this.$route.query.claim_id
                );
                // load claim
                this.$bind(
                    'selectedClaim',
                    this.$fire.doc(`claims/${this.$route.query.claim_id}`),
                    {maxRefDepth: 0}
                ).then((loadedClaim) => {
                    console.log('PlanBudgets: loaded claim', loadedClaim);
                    if (loadedClaim) {
                        // find the associated records for claim in loaded data
                        const budgetId = loadedClaim.budget.split('/').pop();
                        // find the budget's category from the plan
                        const data = val.data.find((b) => {
                            console.log(
                                'PlanBudgets: budget search',
                                budgetId,
                                b.budget.id
                            );
                            return b.budget.id === budgetId;
                        });

                        console.log('PlanBudgets: found data', data);

                        // if the category was found, this claim is valid to display for the current plan
                        if (data) {
                            // switch to the budget tab for this claim
                            this.selectedTab = data.budget.id;

                            const user = this.user;

                            const claim = {
                                ...loadedClaim,
                                id: loadedClaim.id,
                                user: this.$fire.doc(`users/${user.id}`),
                                budget: this.$fire.doc(
                                    `budgets/${data.budget.id}`
                                ),
                                category: this.$fire.doc(
                                    `categories/${data.category.id}`
                                ),
                            };

                            // open this claim in modal
                            this.$bus.$emit('claim-modal', {
                                user,
                                budget: data.budget,
                                category: data.category,
                                claim,
                            });
                        }
                    }
                });
            }
        },
    },
    methods: {
        handleAddBudget() {
            this.$bus.$emit('budget-modal');
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '../../assets/scss/vars';
.plan-budgets {
    background-color: white;
    border-radius: 8px;
    flex: 1;
    overflow-y: scroll;
    position: relative;
    ::v-deep {
        .el-tabs__header {
            padding: 20px 20px 0;
            background: white;
            position: sticky;
            top: 0;
            z-index: 1;
        }
        .el-tabs__content {
            padding: 0 20px 20px;
        }
    }
}

::v-deep .el-tabs__new-tab {
    background-color: $purple-rain;
    color: white;
    border: none;
}
</style>
